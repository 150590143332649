import { BlobServiceClient } from '@azure/storage-blob';

export type TOptions = {
  blobHTTPHeaders: {
    blobContentType: string;
  };
}

export async function uploadMediaFromClient(file: File, blobName: string, options?: TOptions) {
  const blobServiceClient = new BlobServiceClient(`https://${process.env.VUE_APP_AZURE_STORAGE_NAME}.blob.core.windows.net?sp=racwdli&st=2024-02-23T19:19:23Z&se=2026-02-24T03:19:23Z&sv=2022-11-02&sr=c&sig=zl6vp7kr%2BCQjZ4xsBlJURV%2FzqOgAp%2FOEe%2FIx9ITSptI%3D`);
  const containerClient = blobServiceClient.getContainerClient(process.env.VUE_APP_AZURE_STORAGE_CONTAINER!);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);

  return await blockBlobClient.uploadData(file, options);
}

export async function deleteMediaFromClient(blobName: string) {
  const blobServiceClient = new BlobServiceClient(`https://${process.env.VUE_APP_AZURE_STORAGE_NAME}.blob.core.windows.net?sp=racwdli&st=2024-02-23T19:19:23Z&se=2026-02-24T03:19:23Z&sv=2022-11-02&sr=c&sig=zl6vp7kr%2BCQjZ4xsBlJURV%2FzqOgAp%2FOEe%2FIx9ITSptI%3D`);
  const containerClient = blobServiceClient.getContainerClient(process.env.VUE_APP_AZURE_STORAGE_CONTAINER!);

  return await containerClient.deleteBlob(blobName)
}
